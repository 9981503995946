<template>
    <div class="wrap">
        <CancelModal v-if="cancelStatus" />
        <popup-modal v-if="isPopUpModal" :text="popupModalText" @close-modal="onCloseModal" />
        <popup-modal v-if="isPopUpErrorModal" :text="popupModalText" @close-modal="isPopUpErrorModal = false" />
        <CancelCheckModal
            v-if="cancelCheckStatus"
            :curationinfo="curationInfo"
            :isRegistCard="isRegistCard"
            :spaceInfo="spaceInfo"
            @closeCancel="cancelCheckStatus = false"
            @showCancel="cancelStatus = true"
            @deletspace="onDeleteSpace"
        />
        <!--############# PC #############-->
        <div class="space_pc">
            <section class="main registration">
                <div class="main_box">
                    <div class="main_box_title">
                        <h1 class="mb_0px">스페이스 등록</h1>
                        <div @click="linkMySpace()">
                            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M9.81055 7.39387L16.8109 0.393555L18.8105 2.39324L11.8102 9.39355L18.8105 16.3939L16.8109 18.3936L9.81055 11.3932L2.81023 18.3936L0.810547 16.3939L7.81086 9.39355L0.810547 2.39324L2.81023 0.393555L9.81055 7.39387Z"
                                    fill="white"
                                />
                            </svg>
                        </div>
                    </div>
                    <div class="myspace_content_form">
                        <div class="form_text">
                            <h3 class="mb_0px">주로 어떤 용도로 차량을 이용하시나요?</h3>
                        </div>
                        <div class="pagnation">
                            <span></span>
                            <span style="width: 32%"></span>
                        </div>

                        <ul class="three_type fade-up acodian_tab carpurpose_list" id="car_purpose">
                            <li
                                v-for="(item, index) in pcCarPurPose1"
                                :key="item.id"
                                class="carpurpose_list_item cursor"
                                :class="{ last: index === pcCarPurPose1.length - 1 }"
                                @click="selectCarPurpose(parseInt(item.value))"
                            >
                                <img class="car_purpose_img" :src="item.imgUrl" :alt="item.koText" />
                                <div
                                    class="car_select_status"
                                    :class="{ active: carPurpose.carPurposeNo.includes(parseInt(item.value)) }"
                                >
                                    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M10.001 1.52898C12.35 -0.58002 15.98 -0.51002 18.243 1.75698C20.505 4.02498 20.583 7.63698 18.479 9.99298L9.99901 18.485L1.52101 9.99298C-0.582994 7.63698 -0.503994 4.01898 1.75701 1.75698C4.02201 -0.50702 7.64501 -0.58302 10.001 1.52898Z"
                                            fill="white"
                                        />
                                    </svg>
                                </div>
                                <h5 class="carpurpose_text">{{ item.koText }}</h5>
                            </li>
                        </ul>
                        <ul class="three_type fade-up acodian_tab carpurpose_list second" id="car_purpose2">
                            <li
                                v-for="(item, index) in pcCarPurPose2"
                                :key="item.id"
                                class="carpurpose_list_item cursor"
                                :class="{ last: index === pcCarPurPose1.length - 1 }"
                                @click="selectCarPurpose(parseInt(item.value))"
                            >
                                <img class="car_purpose_img" :src="item.imgUrl" :alt="item.koText" />
                                <div
                                    class="car_select_status"
                                    :class="{ active: carPurpose.carPurposeNo.includes(parseInt(item.value)) }"
                                >
                                    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M10.001 1.52898C12.35 -0.58002 15.98 -0.51002 18.243 1.75698C20.505 4.02498 20.583 7.63698 18.479 9.99298L9.99901 18.485L1.52101 9.99298C-0.582994 7.63698 -0.503994 4.01898 1.75701 1.75698C4.02201 -0.50702 7.64501 -0.58302 10.001 1.52898Z"
                                            fill="white"
                                        />
                                    </svg>
                                </div>
                                <h5 class="carpurpose_text">{{ item.koText }}</h5>
                            </li>
                        </ul>
                        <div class="car_purpose_checkbox">
                            <img
                                class="car_purpose_checkbox_img"
                                @click="purposeCheck"
                                :src="
                                    carPurposeCheck ? '/media/img/get_out_checkbox_active.svg' : '/media/img/get_out_checkbox_off.svg'
                                "
                                width="28"
                                alt="get_out_checkbox_off"
                            />
                            <span class="checkbox_text" @click="purposeCheck">플레이어 이용시마다 이용 목적 묻기</span>
                        </div>
                        <footer class="insert_bottom dp_inlineblock box_contents">
                            <ul class="insert_bottom_box">
                                <button class="insert_page_btn prev" @click="clickPre()">이전</button>
                                <button
                                    :disabled="carPurpose.carPurposeNo.length === 0"
                                    class="insert_page_btn next"
                                    @click="updateCuration()"
                                >
                                    다음
                                </button>
                            </ul>
                        </footer>
                    </div>
                </div>
            </section>
        </div>
        <!--############# Mobile #############-->
        <div class="space_mo h_100">
            <div class="mo_space_header">
                <div class="mo_header_box">
                    <a>
                        <img src="/media/images/icon_back_24.svg" @click="clickPre()" />
                    </a>
                    <h2>스페이스 등록</h2>
                    <div class="btn_close" @click="linkMySpace()">
                        <img src="/media/images/icon_close_24.svg" />
                    </div>
                </div>
                <div class="pagnation">
                    <span></span>
                    <span style="width: 32%"></span>
                </div>
            </div>
            <section class="main registration type_choice">
                <div class="mo_main_box">
                    <div class="mo_myspace_content_form">
                        <div class="form_text">
                            <h3 class="mb_0px">주로 어떤 용도로 차량을 이용하시나요?</h3>
                        </div>
                        <ul class="carpurpose_list">
                            <li
                                v-for="(item, index) in moCarPurPose1"
                                :key="item.id"
                                class="carpurpose_list_item cursor"
                                :class="{ last: index === moCarPurPose1.length - 1 }"
                                @click="selectCarPurpose(parseInt(item.value))"
                            >
                                <img class="car_purpose_img" :src="item.imgUrl" :alt="item.koText" />
                                <div
                                    class="car_select_status"
                                    :class="{ active: carPurpose.carPurposeNo.includes(parseInt(item.value)) }"
                                >
                                    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M10.001 1.52898C12.35 -0.58002 15.98 -0.51002 18.243 1.75698C20.505 4.02498 20.583 7.63698 18.479 9.99298L9.99901 18.485L1.52101 9.99298C-0.582994 7.63698 -0.503994 4.01898 1.75701 1.75698C4.02201 -0.50702 7.64501 -0.58302 10.001 1.52898Z"
                                            fill="white"
                                        />
                                    </svg>
                                </div>
                                <h5 class="carpurpose_text">{{ item.koText }}</h5>
                            </li>
                        </ul>
                        <ul class="carpurpose_list second">
                            <li
                                v-for="(item, index) in moCarPurPose2"
                                :key="item.id"
                                class="carpurpose_list_item cursor"
                                :class="{ last: index === moCarPurPose2.length - 1 }"
                                @click="selectCarPurpose(parseInt(item.value))"
                            >
                                <img class="car_purpose_img" :src="item.imgUrl" :alt="item.koText" />
                                <div
                                    class="car_select_status"
                                    :class="{ active: carPurpose.carPurposeNo.includes(parseInt(item.value)) }"
                                >
                                    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M10.001 1.52898C12.35 -0.58002 15.98 -0.51002 18.243 1.75698C20.505 4.02498 20.583 7.63698 18.479 9.99298L9.99901 18.485L1.52101 9.99298C-0.582994 7.63698 -0.503994 4.01898 1.75701 1.75698C4.02201 -0.50702 7.64501 -0.58302 10.001 1.52898Z"
                                            fill="white"
                                        />
                                    </svg>
                                </div>
                                <h5 class="carpurpose_text">{{ item.koText }}</h5>
                            </li>
                        </ul>
                        <ul class="carpurpose_list second">
                            <li
                                v-for="(item, index) in moCarPurPose3"
                                :key="item.id"
                                class="carpurpose_list_item cursor"
                                :class="{ last: index === moCarPurPose3.length - 1 }"
                                @click="selectCarPurpose(parseInt(item.value))"
                            >
                                <img class="car_purpose_img" :src="item.imgUrl" :alt="item.koText" />
                                <div
                                    class="car_select_status"
                                    :class="{ active: carPurpose.carPurposeNo.includes(parseInt(item.value)) }"
                                >
                                    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M10.001 1.52898C12.35 -0.58002 15.98 -0.51002 18.243 1.75698C20.505 4.02498 20.583 7.63698 18.479 9.99298L9.99901 18.485L1.52101 9.99298C-0.582994 7.63698 -0.503994 4.01898 1.75701 1.75698C4.02201 -0.50702 7.64501 -0.58302 10.001 1.52898Z"
                                            fill="white"
                                        />
                                    </svg>
                                </div>
                                <h5 class="carpurpose_text">{{ item.koText }}</h5>
                            </li>
                        </ul>
                        <div class="car_purpose_checkbox" @click="purposeCheck">
                            <img
                                class="car_purpose_checkbox_img"
                                :src="
                                    carPurposeCheck ? '/media/img/get_out_checkbox_active.svg' : '/media/img/get_out_checkbox_off.svg'
                                "
                                width="28"
                                alt="get_out_checkbox_off"
                            />
                            <span class="checkbox_text">플레이어 이용시마다 이용 목적 묻기</span>
                        </div>
                        <footer class="insert_bottom">
                            <ul class="insert_bottom_box">
                                <button
                                    class="insert_page_btn next"
                                    :disabled="carPurpose.carPurposeNo.length === 0"
                                    @click="updateCuration()"
                                >
                                    다음
                                </button>
                            </ul>
                        </footer>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api';
import CancelCheckModal from '@/components/modal/Space/CancelCheckModal.vue';
import CancelModal from '@/components/modal/Space/CancelModal.vue';
import axios from 'axios';
import VueCookies from 'vue-cookies';
import router from '@/router';
import PopUpModal from '@/components/modal/common/PopUpModal.vue';
import { updateConfirmCuration } from '@/service/api/profileApi';
export default defineComponent({
  data () {
    return {
      cancelStatus: false,
      cancelCheckStatus: false,
      carPurpose: {
        carPurposeNo: []
      },
      tempList: [],
      selectItem: [],
      filterList: [],
      carPurposeCheck: true,
      isRegistCard: true,
      popupModalText: '',
      isPopUpModal: false,
      isPopUpErrorModal: false,
      curationInfo: {},
      curationId: '',
      spaceId: localStorage.getItem('spaceId')
    };
  },
  components: {
    CancelModal,
    CancelCheckModal,
    'popup-modal': PopUpModal
  },
  methods: {
    onDeleteSpace (msg) {
      this.isPopUpModal = true;
      this.popupModalText = msg;
    },
    selectCarPurpose (value) {
      this.carPurpose.carPurposeNo = [value];
    },
    checkRegistCard () {
      const userId = VueCookies.get('userId');
      const aToken = VueCookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };

      axios
        .post('/api/product/getMyCardInfo', { userId }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            const result = res.data.result;
            result != null ? (this.isRegistCard = true) : (this.isRegistCard = false);
          }
        })
        .catch(e => {
          console.log(e);
        });
    },
    purposeCheck () {
      // carPurposeCheck
      this.carPurposeCheck = !this.carPurposeCheck;
    },
    linkMySpace () {
      var spaceId = localStorage.getItem('spaceId');
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };

      axios
        .post('/api/profile/checkSubscribe', { spaceId }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            if (res.data.result === 'S') {
              this.cancelCheckStatus = true;
              this.curationInfo = this.filterCurationInfo();
            } else {
              router.push({ name: 'Player' });
            }
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    filterCurationInfo () {
      var spaceId = localStorage.getItem('spaceId');
      var carPurpose = this.carPurpose.carPurposeNo
        .sort((a, b) => {
          return a - b;
        })
        .toString()
        ? this.carPurpose.carPurposeNo
          .sort((a, b) => {
            return a - b;
          })
          .toString()
        : null;
      var carPurposeCheck = this.carPurposeCheck ? 'Y' : 'N';
      let genreArray = [];
      let meotionArray = [];
      let genre = null;
      let emotion = null;
      let carMood = null;
      this.carPurpose.carPurposeNo.forEach(val => {
        const item = this.$store.getters['player/getCarGenreFilter'](parseInt(val));
        const emotionItem = this.$store.getters['player/getCarEmotionFilter'](parseInt(val));
        genreArray = [...genreArray, ...item];
        meotionArray = [...meotionArray, ...emotionItem];
      });
      const genreSet = new Set(genreArray);
      const emotionSet = new Set(meotionArray);

      genre = Array.from(genreSet)
        .sort((a, b) => a - b)
        .join(',');
      emotion = Array.from(emotionSet)
        .sort((a, b) => a - b)
        .join(',');
      if (this.carPurpose.carPurposeNo.length > 1) {
        const sort = this.carPurpose.carPurposeNo.sort((a, b) => a - b);
        carMood = this.$store.getters['player/getCarMoodFilter'](parseInt(sort[0]));
      } else {
        carMood = this.$store.getters['player/getCarMoodFilter'](parseInt(this.carPurpose.carPurposeNo[0]));
      }
      return { spaceId, carPurpose, carPurposeCheck, genre, emotion, carMood };
    },
    async updateCuration () {
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };

      const filter = this.filterCurationInfo();

      try {
        const { data: curationTempData } = await axios.post('/api/profile/updateCurationTemp', filter, { headers });
        const { resultCd } = curationTempData;
        if (resultCd === '0000') {
          const { data: confirmCurationData } = await updateConfirmCuration(this.spaceId, this.curationId);
          const { resultCd: confirmCurationResultCd } = confirmCurationData;
          if (confirmCurationResultCd === '0000') {
            this.$router.push({ name: 'SpaceInsertStoreCountry' });
          }
        }
      } catch (err) {
        if (err.response) {
          const { data } = err.response;
          const { resultCd, resultMsg } = data;
          if (resultCd === '400' && resultMsg === '삭제된 스페이스입니다.') {
            this.popupModalText = resultMsg;
            this.isPopUpModal = true;
            return false;
          } else {
            this.popupModalText = resultMsg;
            this.isPopUpErrorModal = true;
            return false;
          }
        }
        console.log(err);
      }
    },
    onCloseModal () {
      this.isPopUpModal = false;
      this.$router.push({ name: 'MySpace' });
    },
    clickPre () {
      // SpaceInsertCar
      this.$router.push({ name: 'SpaceInsertCar' });
    },
    getCurationInfo () {
      var spaceId = localStorage.getItem('spaceId');
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };

      axios
        .post('/api/profile/getCurationInfo', { spaceId }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            var result = res.data.result;
            this.curationId = result.curationId;
            result.carPurposeCheck === 'N' ? (this.carPurposeCheck = false) : (this.carPurposeCheck = true);
            this.tempList = result.carPurpose;
            const carPurPoseList = this.$store.getters['curation/getCarPurpose'] || [];

            if (this.tempList && this.tempList.length > 0) {
              const find = carPurPoseList.find(item => item.koText === this.tempList[0]);
              if (find) {
                this.carPurpose.carPurposeNo = [parseInt(find.value)];
              }
            }
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  mounted () {
    this.getCurationInfo();
    this.checkRegistCard();
  },
  computed: {
    pcCarPurPose1 () {
      return this.$store.getters['curation/getCarPurpose'].slice(0, 5);
    },
    pcCarPurPose2 () {
      return this.$store.getters['curation/getCarPurpose'].slice(5, 7);
    },
    moCarPurPose1 () {
      return this.$store.getters['curation/getCarPurpose'].slice(0, 3);
    },
    moCarPurPose2 () {
      return this.$store.getters['curation/getCarPurpose'].slice(3, 6);
    },
    moCarPurPose3 () {
      return this.$store.getters['curation/getCarPurpose'].slice(6, 9);
    }
  }
});
</script>

<style src="@/assets/css/content2.css"></style>
<style src="@/assets/css/font.css"></style>
<style src="@/assets/css/insertspace.css" scoped></style>
