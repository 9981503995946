import { render, staticRenderFns } from "./SpaceInsertCarPurpose.vue?vue&type=template&id=0179894a&scoped=true"
import script from "./SpaceInsertCarPurpose.vue?vue&type=script&lang=js"
export * from "./SpaceInsertCarPurpose.vue?vue&type=script&lang=js"
import style0 from "@/assets/css/content2.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "@/assets/css/font.css?vue&type=style&index=1&prod&lang=css&external"
import style2 from "@/assets/css/insertspace.css?vue&type=style&index=2&id=0179894a&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0179894a",
  null
  
)

export default component.exports